import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PricingHistoryDto} from 'app/dto/pricing-history.interface';
import {ValidationConfigurationsDto} from 'app/dto/validation-configurations.interface';
import {environment} from 'environments/environment';
import {Moment} from 'moment';
import {Observable} from 'rxjs';
import {PageableRequestParameters, PageableResponse} from '../dto';
import {PricesData} from '../dto/audit.interface';
import {AudtiLogEvent} from '../dto/history.interface';
import {InternalUser} from '../dto/internal-user.interface';
import {MinimalPricesConfigurationInterface, PriceConfigurationInterface} from '../dto/prices-configuration.interface';
import {KwMatrixEntries, KwPriceConfigurationInterface} from '../dto/rebt.interface';
import {map} from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-wm-redirect': 'wm-backoffice'
  })
};

const defaultConfigurationsSortParameters: PageableRequestParameters = {
  pageSize: 20,
  sortParam: 'startDate',
  direction: 'desc'
};

@Injectable({providedIn: 'root'})
export class BackofficeService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  private createQueryParams(params: { [key: string]: (string | number) }): string {
    const queryParams = new URLSearchParams();

    Object.entries(params)
    .filter(([, value]) => !!value)
    .forEach(([key, value]) => queryParams.set(key, value as string));

    return queryParams.toString();
  }

  private convertToSortParams(params: PageableRequestParameters) {
    return {
      page: params.pageIndex || 0,
      size: params.pageSize || 20,
      sort: params.sortParam ? `${params.sortParam},${params.direction}` : undefined
    };
  }

  getUsers(params: PageableRequestParameters): Observable<PageableResponse<InternalUser>> {
    const url = this.baseUrl + `/api/users?${this.createQueryParams(this.convertToSortParams(params))}`;

    return this.http.get<PageableResponse<InternalUser>>(url, httpOptions);
  }

  createUser(value: InternalUser): Observable<any> {
    const url = this.baseUrl + '/api/users';
    return this.http.post<InternalUser>(url, value, httpOptions);
  }

  deactivateUserById(id: number) {
    const url = this.baseUrl + `/api/users/${id}/deactivate`;
    return this.http.post<any>(url, null, httpOptions);
  }

  activateUserById(id: number) {
    const url = this.baseUrl + `/api/users/${id}/activate`;
    return this.http.post<any>(url, null, httpOptions);
  }

  getUserById(id: number): Observable<InternalUser> {
    const url = this.baseUrl + `/api/users/${id}`;
    return this.http.get<InternalUser>(url, httpOptions);
  }

  updateUserRole(user: InternalUser): Observable<any> {
    const url = this.baseUrl + `/api/users/${user.id}/update`;
    return this.http.post<InternalUser>(url, user, httpOptions);
  }

  getPrices(params): Observable<PricesData> {
    const url = this.baseUrl + '/api-gateway/prices' + '?' + this.createQueryParams(params);

    return this.http.get<PricesData>(url, httpOptions);
  }

  downloadHistory(params) {
    const url = this.baseUrl + `/api/user-log/xls?from=${params.from}&to=${params.to}`;

    const options = {
      ...httpOptions,
      responseType: 'blob' as 'json',
    };

    return this.http.get(url, options);
  }

  getHistory(): Observable<PageableResponse<AudtiLogEvent>> {
    const url = this.baseUrl + '/api/user-log';
    return this.http.get<PageableResponse<AudtiLogEvent>>(url, httpOptions);
  }

  getAggregatedHistory(): Observable<AudtiLogEvent[]> {
    const url = this.baseUrl + '/api/user-log/aggregated';
    return this.http.get<AudtiLogEvent[]>(url, httpOptions);
  }

  getKwPricingConfigurations(): Observable<PageableResponse<KwPriceConfigurationInterface>> {
    const url = this.baseUrl + `/api-gateway/kw-matrix/configuration?${this.createQueryParams(this.convertToSortParams(defaultConfigurationsSortParameters))}`;
    return this.http.get<PageableResponse<KwPriceConfigurationInterface>>(url, httpOptions);
  }

  createKwPricingConfigurations(pricingConfig: KwPriceConfigurationInterface) {
    const url = this.baseUrl + '/api-gateway/kw-matrix/configuration';
    return this.http.post(url, pricingConfig, httpOptions);
  }

  updateKwPricingConfiguration(id: number, pricingConfig: KwPriceConfigurationInterface) {
    const url = this.baseUrl + '/api-gateway/kw-matrix/configuration/' + id;
    return this.http.put(url, pricingConfig, httpOptions);
  }

  deleteKwPricingConfiguration(id: number) {
    const url = this.baseUrl + '/api-gateway/kw-matrix/configuration/' + id;
    return this.http.delete(url, httpOptions);
  }

  getPricingConfigurations(): Observable<PageableResponse<PriceConfigurationInterface>> {
    const url = this.baseUrl + `/api-gateway/configurations/margin?${this.createQueryParams(this.convertToSortParams(defaultConfigurationsSortParameters))}`;
    return this.http.get<PageableResponse<PriceConfigurationInterface>>(url, httpOptions);
  }

  createPricingConfigurations(pricingConfig: PriceConfigurationInterface) {
    const url = this.baseUrl + '/api-gateway/configurations/margin';
    return this.http.post(url, pricingConfig, httpOptions);
  }

  updatePricingConfiguration(id: number, pricingConfig: PriceConfigurationInterface) {
    const url = this.baseUrl + '/api-gateway/configurations/margin/' + id;
    return this.http.put(url, pricingConfig, httpOptions);
  }

  deletePricingConfiguration(id: number) {
    const url = this.baseUrl + '/api-gateway/configurations/margin/' + id;
    return this.http.delete(url, httpOptions);
  }

  getMinimalPriceConfigurations(): Observable<PageableResponse<MinimalPricesConfigurationInterface>> {
    const url = this.baseUrl + `/api-gateway/configurations/minimal?${this.createQueryParams(this.convertToSortParams(defaultConfigurationsSortParameters))}`;
    return this.http.get<PageableResponse<MinimalPricesConfigurationInterface>>(url, httpOptions);
  }

  createMinimalPriceConfigurations(pricingConfig: MinimalPricesConfigurationInterface) {
    const url = this.baseUrl + '/api-gateway/configurations/minimal';
    return this.http.post(url, pricingConfig, httpOptions);
  }

  updateMinimalPriceConfigurations(id: number, pricingConfig: MinimalPricesConfigurationInterface) {
    const url = this.baseUrl + '/api-gateway/configurations/minimal/' + id;
    return this.http.put(url, pricingConfig, httpOptions);
  }

  deleteMinimalPriceConfigurations(id: number) {
    const url = this.baseUrl + '/api-gateway/configurations/minimal/' + id;
    return this.http.delete(url, httpOptions);
  }

  getValidationConfigurations(): Observable<ValidationConfigurationsDto> {
    const url = this.baseUrl + '/api-gateway/configurations/validation';
    return this.http.get<ValidationConfigurationsDto>(url, httpOptions);
  }

  setValidationConfigurations(value: ValidationConfigurationsDto) {
    const url = this.baseUrl + `/api-gateway/configurations/validation`;
    return this.http.post<ValidationConfigurationsDto>(url, value, httpOptions);
  }

  getPricingHistory(params: PageableRequestParameters = {}, source: string = ''): Observable<PageableResponse<PricingHistoryDto>> {
    const searchParams = this.convertToSortParams(params);

    const url = this.baseUrl + `/api-gateway/history?${this.createQueryParams({
      ...searchParams,
      source
    })}`;

    return this.http.get<PageableResponse<PricingHistoryDto>>(url, httpOptions);
  }

  rejectTAPriceFile(id: number, reason: string): Observable<any> {
    const url = this.baseUrl + `/api-gateway/history/${id}/reject`;
    return this.http.post(url, {id, reason}, httpOptions);
  }

  approveTAPriceFile(id: number, dto: { startDate: Moment }): Observable<any> {
    const url = this.baseUrl + `/api-gateway/history/${id}/approve`;
    return this.http.post(url, {
      startDate: dto.startDate.format('YYYY-MM-DD'),
      username: '1',
      id: id
    }, httpOptions);
  }

  changeTAPriceFileStartDate(id: number, dto: { startDate: Moment }): Observable<any> {
    const url = this.baseUrl + `/api-gateway/history/${id}`;
    return this.http.put(url, {
      startDate: dto.startDate.format('YYYY-MM-DD'),
      id: id
    }, httpOptions);
  }

  getReport(reportId: number): Observable<BlobPart> {
    const url = this.baseUrl + `/api-gateway/history/reports/${reportId}`;
    const options = {
      ...httpOptions,
      responseType: 'blob' as 'json'
    };

    return this.http.get<BlobPart>(url, options);
  }

  async getKwMatrixEntries(date?: Date): Promise<KwMatrixEntries> {
    let url = this.baseUrl + '/api-gateway/kw-matrix';

    if (date instanceof Date && !isNaN(date.getTime())) {
      try {
        const formattedDate = this.formatDate(date);
        url += `?date=${formattedDate}`;
      } catch (error) {
        throw new Error('Failed to format date');
      }
    }

    try {
      return await this.http.get<KwMatrixEntries>(url, httpOptions).toPromise();
    } catch (error) {
      throw new Error('Failed to fetch data from API');
    }
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getKwMatrixStartDates(): Observable<PageableResponse<Date>> {
    const url = this.baseUrl + '/api-gateway/kw-matrix/start-dates';

    return this.http.get<PageableResponse<number[]>>(url, httpOptions).pipe(
      map(response => {
        const transformedContent: Date[] = response.content.map((dateArray: number[]) => {
          return new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
        });

        return {
          ...response,
          content: transformedContent
        };
      })
    );
  }
}
